import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import './style.scss'
import Intro from '../../animations/Intro'
import Seo from '../../components/seo'

import MainFrameCover from '../../components/MainFrameCover'
import Footer from '../../components/Footer'

import Modal from '../../components/Modal'
import LoginBox from '../../components/Blocks/LoginBox'
import SubscribeBox from '../../components/Blocks/SubscribeBox'

import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

function Post(props) {

  const post = props?.data?.contentfulBlogPost
  const date = new Date(post?.publishDate)

  function goBack() {
    navigate(-1)
  }

  useEffect(() => {
    const header = document?.getElementById('master-header-mimic')
    const sticky = document?.getElementById('sticky-go-back')
    if (sticky) {
      sticky.style.top = header.offsetHeight - 11 + 'px'
    }
  }, [])

  const defaultImage = props.data?.contentfulOptions?.defaultPressRoomImage

  return (
    <>
      <MainFrameCover />

      <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal>

      <main id="mainframe" className={`c0 layer active main-${props?.pageContext?.slug === "/" ? "frontpage" : props?.pageContext?.slug}`}>

        <Seo title={post?.metaTitle ? post?.metaTitle : post?.title} description={post?.metaDescription ? post.metaDescription : post?.description?.description ? post?.description?.description : null} image={post?.heroImage?.file?.url ? post?.heroImage?.file?.url : defaultImage?.file?.url} path={props.location?.pathname ? props.location?.pathname : ''} />

        <article className="single-post">
          <div id="sticky-go-back" className="go-back" onClick={(e) => goBack(e)} onKeyDown={(e) => goBack(e)} role="button" aria-label="Go back" tabIndex={0}>
            <Intro visible={true} in={{ border: 500, bg: 500, blink: 0 }} delayIn={200} className="c3 t border-right-off">
              <h6><i className={"far fa-arrow-left"}></i> Go Back</h6>
            </Intro>
          </div>
          <div className="image-main">
            {post?.heroImage || defaultImage ?
              <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={0} stay={false} className="c5 t border-c5 image">
                <GatsbyImage
                  image={post?.heroImage?.gatsbyImageData ? post?.heroImage?.gatsbyImageData : defaultImage?.gatsbyImageData}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  title={post?.heroImage?.title}
                  alt={post?.heroImage?.description}
                />
              </Intro>
              : null}
            {post?.category ? <h6>{post?.category[0].title}</h6> : null}
          </div>
          <Intro visible={true} in={{ fadeBottom: 500 }} delayIn={200} className="">
            <div className="content-main">
              <div className="content-main-title">
                <h2>{post?.title}</h2>
                {date ? <h6 style={{ marginTop: "-1em" }}>{date.toLocaleDateString(['en-US'], { month: 'numeric', day: 'numeric', year: 'numeric' })}</h6> : null}
              </div>
              <div className="content-main-padd" dangerouslySetInnerHTML={{ __html: post?.body?.childMarkdownRemark.html }}>
              </div>
            </div>
          </Intro>
          {/* <pre>
              <code>{JSON.stringify(props, null, 2)}</code>
            </pre> */}
        </article>

        <Footer />

      </main>

    </>
  )
}

export default Post

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost ( slug: { eq: $slug } ) {
      title
      slug
      publishDate
      # category {
      #   title
      # }
      description {
        description
      }
      metaTitle
      metaDescription
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920])
        title
        description
        file {
          url
        }
      }
    }
    contentfulOptions {
      defaultPressRoomImage {
        gatsbyImageData(quality: 70, layout: CONSTRAINED, breakpoints: [300, 500, 750, 1080, 1366, 1920], formats: WEBP)
        title
        description
        file {
          url
        }
      }
    }
  }
`